<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="align-center pr-2 mb-5" style="width: 100%">
          <div class="d-inline-flex align-center justify-space-between max-width">
            <div class="d-flex align-center">
              <v-switch
                v-model="activeOnly"
                inset
                dense
                data-cy="switch-active-only"
                :label="$lang.labels.activeOnly"
                class="pl-2 pt-1 pr-3"
              >
              </v-switch>
              <v-text-field
                v-model="search"
                class="custom-search-perm"
                outlined
                clearable
                dense
                hide-details
                :label="$lang.labels.searchByName"
                data-cy="search-by-name"
              ></v-text-field>
              <v-autocomplete
                v-model="processId"
                outlined
                dense
                :items="processes"
                :loading="isLoadingProcesses"
                :search-input.sync="searchProcesses"
                hide-no-data
                clearable
                hide-details
                item-text="name"
                item-value="id"
                :label="$lang.labels.process"
                :placeholder="$lang.actions.startTyping"
                prepend-inner-icon="mdi-cloud-search-outline"
                class=" ml-2 custom-search-perm"
              ></v-autocomplete>
              <v-select
                v-model="service"
                :items="formattedServices"
                outlined
                dense
                hide-details
                clearable
                :label="$lang.labels.service"
                class="ml-2 custom-search-perm"
              >
              </v-select>
            </div>

            <!-- Right aligned buttons -->
            <div class="d-flex align-center ml-auto">
              <v-btn
                text
                light
                class="ml-2"
                color="primary"
                @click="$emit('fetchMessagings', { options: options, search: search && search.length > 1 ? search : '', processId: processId, resetPage: true })"
              >
                <v-icon dense small>mdi-refresh</v-icon>
              </v-btn>
              <v-btn
                v-if="roles.includes('TRIGGER_CREATOR')"
                color="accent"
                class="color-accent-text mr-1"
                :to="{ name: 'messagingCreate', params: { lang: $lang.current_lang } }"
              >
                {{ $lang.actions.create }}
              </v-btn>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.service="{ item }">
        <div>{{ $lang.status[item.service] }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div class="custom-active-deactive">
          <p :class="{'custom-active': item.status === 'ACTIVE', 'custom-deactivated': item.status !== 'ACTIVE', 'm-0 p-0 mb-0': true }">
            {{ $lang.status[item.status] }}
          </p>
        </div>
      </template>

      <template v-slot:item.comment="{ item }">
        <div style="white-space: nowrap;overflow: hidden;max-width: 300px;text-overflow: ellipsis">{{ item.comment }}</div>
      </template>

      <template v-slot:item.basicAccessAuthentication="{ item }">
        <div>{{ item.basicAccessAuthentication ? $lang.status.YES : $lang.status.NO }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex justify-end">
          <!-- Separate Edit Button -->
          <v-btn
            text
            color="primary"
            :to="{ name: 'messagingEdit', params: { id: item.id } }"
          >
            <v-icon small class="mr-1">mdi-lead-pencil</v-icon>
            {{ item.isSystem ? $lang.actions.view : $lang.actions.edit }}
          </v-btn>

          <!-- Button with mdi-dots-vertical Icon -->
          <v-menu class="custom-edit-menu" offset-y nudge-left="95" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                variant="text"
                icon
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <!-- Menu with actions -->
            <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid ;">
              <v-list dense>
                <!-- Deactivate/Activate button -->
                <v-list-item
                  link
                  class="text--secondary font-weight-light"
                  @click="$emit('changeStatus', {id: item.id, status: item.status})"
                >
                  {{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                </v-list-item>

                <!-- Copy Create button -->
                <v-list-item
                  v-if="roles.includes('TRIGGER_CREATOR')"
                  link
                  class="text--secondary font-weight-light"
                  @click="copyCreateResource(item, 'messagingCreate', $router, '', $options.filters.formatDateTime(new Date()), false, true)"
                >
                  {{ $lang.actions.copyCreate }}
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getProcessByIdUsingGET as getProcess, getProcessesUsingGET as getProcesses, getTriggerMessagingServicesUsingGET as getServices } from '@/utils/api'
import { bus } from '@/main'
import { copyCreateResource } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'
import { debounce } from 'lodash'

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.service, value: 'service' },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      roles: [],
      isLoadingProcesses: false,
      processId: '',
      processes: [],
      searchProcesses: '',
      service: '',
      services: []
    }
  },
  computed: {
    formattedServices() {
      return this.services.map((x) => {
        return {
          text: this.$lang.status[x],
          value: x
        }
      })
    },
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, service: this.service })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler: debounce( function (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchMessagings', { options: this.options, search: val, processId: this.processId, resetPage: true, service: this.service })
        if (!this.lock && val === null || val === '') this.$emit('fetchMessagings', { options: this.options, search: '', processId: this.processId, resetPage: true, service: this.service })
        this.savePreFill()
      }, 500)
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service } })
        this.savePreFill()
      }
    },
    searchProcesses: {
      handler: debounce( function (val) {
        if (val && val.length > 1) this.searchProcessesFunction(val)
      }, 500)
    },
    processId: {
      handler() {
        if (!this.lock) this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service })
        this.savePreFill()
      }
    },
    service: {
      handler() {
        if (!this.lock) this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service })
        this.savePreFill()
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service })
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn' })
    }

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(3, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn' })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id' })
    }

    // eslint-disable-next-line consistent-return
    this.headers.forEach((item, index) => {
      if (item.value === 'actions') {
        if (this.userSettings.display.showComments) {
          this.headers.splice(index, 0, { text: this.$lang.labels.comment, value: 'comment' })
        }
      }
    })

    getServices()
      .then((res) => {
        this.services = res.data.data
      })
      .catch((err) => {
        this.addSnackbar({
          message:  err,
          timeout: 5000,
          color: 'error'
        })          })

    if (this.$route.query && Object.keys(this.$route.query).length > 0) {
      if (this.$route.query && this.$route.query.processId) {
        this.processId = this.$route.query.processId
      }
      if (this.$route.query && this.$route.query.search) {
        this.search = this.$route.query.search
      }
      if (this.$route.query && this.$route.query.service) {
        this.service = this.$route.query.service
      }
      if (this.$route.query.activeOnly) {
        this.activeOnly = this.$route.query.activeOnly === 'true'
        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, service: this.service } })
      } else {
        this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service })
      }
    } else if (localStorage.preFillData) {

      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.messagingList) {
        this.search = preFill.messagingList.search
        this.activeOnly = preFill.messagingList.activeOnly
        this.processId = preFill.messagingList.processId
        this.service = preFill.messagingList.service
        this.options = preFill.messagingList.options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service } })
      } else {
        this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true })
      }
    } else {
      this.$emit('fetchMessagings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', processId: this.processId, resetPage: true, service: this.service })
    }
    if (this.processId) {
      this.loadPreFill()
    } else {
      setTimeout(() => this.lock = false, 100)
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    copyCreateResource,
    loadPreFill() {
      this.isLoadingProcesses = true
      getProcess({
        id : this.processId
      })
        .then((res) => {
          this.processId = ''
          this.processes = [res.data.data]
          this.isLoadingProcesses = false
          this.searchProcesses = res.data.data.name
          this.processId = res.data.data.id
          setTimeout(() => {
            this.lock = false
          }, 50)
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    searchProcessesFunction(val = '') {
      this.isLoadingProcesses = true
      getProcesses({
        name: val || ''
      })
        .then((res) => {
          this.processes = res.data.data.items
          this.isLoadingProcesses = false
        })
        .catch((err) => {
          this.isLoadingProcesses = false
          this.addSnackbar({
            message:  err,
            timeout: 5000,
            color: 'error'
          })          })
    },
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.messagingList) preFill.messagingList = {
          search: '',
          activeOnly: true,
          processId: '',
          service: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.messagingList.search = this.search
        preFill.messagingList.activeOnly = this.activeOnly
        preFill.messagingList.processId = this.processId
        preFill.messagingList.service = this.service
        preFill.messagingList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>

<style>
.v-btn:before {
  background-color: transparent;
}
</style>
